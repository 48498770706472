import { gql } from '@/shared/graphql/generated/cache';

export default gql(/* GraphQL */ `
  query GetLatestFlipSupplyQuery {
    flipSupply: flipSupplyById(id: 1) {
      totalIssuance
      offchainFunds
      annualSystemCompoundedRewards
    }
  }
`);
