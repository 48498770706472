import { isNullish } from '@chainflip/utils/guard';
import Button from './flip-ui-kit/Button';
import LoadingSpinner from './LoadingSpinner';
import { PlainModal } from './molecules/PlainModal';
import {
  type RotationEventType,
  type EpochWithRotationFragment,
} from '../graphql/generated/explorer/graphql';
import Checkmark from '../icons/flip-ui-kit/large/Checkmark';
import Window from '../icons/flip-ui-kit/large/Window';
import Frame from '../icons/Frame';
import { CloseIcon, RotateIcon } from '../icons/large';

type RotationPhaseStatus = 'inactive' | 'started' | 'completed';

const RotationPhaseStatusIcon = ({ status }: { status: RotationPhaseStatus }) => {
  if (status === 'inactive') return <Frame />;
  if (status === 'started') return <LoadingSpinner />;
  if (status === 'completed') return <Checkmark className="text-cf-green-1" />;
  return null;
};

const determineRotationPhaseStatus = (
  triggerEventId?: string,
  completionEventId?: string,
): RotationPhaseStatus => {
  if (completionEventId) return 'completed';
  if (triggerEventId) return 'started';
  return 'inactive';
};

const RotationPhaseDetailRow = ({
  title,
  status,
  linkToExplorer,
  timestamp,
}: {
  title: string;
  status: RotationPhaseStatus;
  linkToExplorer?: string | null;
  timestamp?: string | null;
}) => (
  <div className="flex flex-row items-start justify-between space-x-3 text-14">
    <RotationPhaseStatusIcon status={status} />
    <div className="flex w-3/5 grow flex-col items-start space-y-1">
      <div className="text-white">{title}</div>
      {timestamp && (
        <div className="font-aeonikMono text-12 text-cf-light-2">
          {new Date(timestamp).toLocaleString('en-US')}
        </div>
      )}
    </div>
    {linkToExplorer && (
      <div className="flex flex-row space-x-2">
        <a href={linkToExplorer} target="_blank" rel="noreferrer">
          <Button type="link-secondary" className="h-fit !py-0">
            View on explorer
            <Window />
          </Button>
        </a>
      </div>
    )}
  </div>
);

const getExplorerEventUrl = ({
  blockId,
  indexInBlock,
}: {
  blockId?: number;
  indexInBlock?: number;
}) => {
  if (isNullish(blockId) || isNullish(indexInBlock)) return null;

  const explorerUrl = process.env.NEXT_PUBLIC_EXPLORER_URL || '';

  return `${explorerUrl}/events/${blockId}-${indexInBlock}`;
};

export const RotationInfoModal = ({
  active,
  onCancel,
  epochWithRotation,
}: {
  active: boolean;
  onCancel: () => void;
  epochWithRotation: EpochWithRotationFragment | null;
}) => {
  if (!epochWithRotation) return null;

  const findRotationEvent = (eventType: RotationEventType) =>
    epochWithRotation.rotationEvents.nodes.find((event) => event.type === eventType);

  const activatingKeysRotationPhase = findRotationEvent('ACTIVATING_KEYS_ROTATION_PHASE');
  const auctionCompleted = findRotationEvent('AUCTION_COMPLETED');
  const bitcoinKeygenRequest = findRotationEvent('BITCOIN_KEYGEN_REQUEST');
  const bitcoinKeygenVerificationSuccess = findRotationEvent('BITCOIN_KEYGEN_VERIFICATION_SUCCESS');
  const bitcoinKeyHandoverRequest = findRotationEvent('BITCOIN_KEY_HANDOVER_REQUEST');
  const bitcoinKeyHandoverVerificationSuccess = findRotationEvent(
    'BITCOIN_KEY_HANDOVER_VERIFICATION_SUCCESS',
  );
  const bitcoinVaultActivationCompleted = findRotationEvent('BITCOIN_VAULT_ACTIVATION_COMPLETED');
  const evmKeygenRequest = findRotationEvent('EVM_KEYGEN_REQUEST');
  const evmKeygenVerificationSuccess = findRotationEvent('EVM_KEYGEN_VERIFICATION_SUCCESS');
  const ethereumVaultActivationCompleted = findRotationEvent('ETHEREUM_VAULT_ACTIVATION_COMPLETED');
  const newKeysActivatedRotationPhase = findRotationEvent('NEW_KEYS_ACTIVATED_ROTATION_PHASE');
  const polkadotKeygenRequest = findRotationEvent('POLKADOT_KEYGEN_REQUEST');
  const polkadotKeygenVerificationSuccess = findRotationEvent(
    'POLKADOT_KEYGEN_VERIFICATION_SUCCESS',
  );
  const polkadotVaultActivationCompleted = findRotationEvent('POLKADOT_VAULT_ACTIVATION_COMPLETED');

  const arbitrumVaultActivationCompleted = findRotationEvent('ARBITRUM_VAULT_ACTIVATION_COMPLETED');

  const solanaKeygenRequest = findRotationEvent('SOLANA_KEYGEN_REQUEST');
  const solanaKeygenVerificationSucess = findRotationEvent('SOLANA_KEYGEN_VERIFICATION_SUCCESS');
  const solanaVaultActivationCompleted = findRotationEvent('SOLANA_VAULT_ACTIVATION_COMPLETED');

  return (
    <PlainModal active={active} onCancel={onCancel}>
      <div className="flex flex-col rounded-md border border-cf-gray-4 bg-cf-gray-3 last:border-b-0 [&>div]:border-b [&>div]:border-cf-gray-4 [&>div]:p-4">
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-center space-x-2">
            <RotateIcon className="text-cf-blue-4" />
            <span>Rotation</span>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex flex-row items-center justify-center gap-x-2 text-cf-light-2">
              {newKeysActivatedRotationPhase ? (
                <>
                  <Checkmark className="text-cf-green-1" />
                  <span className="text-14">Completed</span>
                </>
              ) : (
                <>
                  <LoadingSpinner className="stroke-cf-light-2" />
                  <span className="text-14">In progress</span>
                </>
              )}
            </div>

            <button
              onClick={onCancel}
              type="button"
              className="flex h-6 w-6 items-center justify-center rounded-full text-cf-light-2 outline-none transition hover:bg-cf-gray-5"
            >
              <CloseIcon className="transition hover:text-white" />
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="text-12 text-cf-light-2">Auction</div>
          <div className="flex flex-col space-y-2">
            <RotationPhaseDetailRow
              title="Primary and Secondary Set candidates selection"
              linkToExplorer={getExplorerEventUrl({
                blockId: auctionCompleted?.event.block.id,
                indexInBlock: auctionCompleted?.event.indexInBlock,
              })}
              timestamp={auctionCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(undefined, auctionCompleted?.event.id)}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="text-12 text-cf-light-2">Keygen Ceremonies</div>
          <div className="flex flex-col space-y-2">
            <RotationPhaseDetailRow
              title="Polkadot Keygen ceremony"
              linkToExplorer={
                getExplorerEventUrl({
                  blockId: polkadotKeygenVerificationSuccess?.event.block.id,
                  indexInBlock: polkadotKeygenVerificationSuccess?.event.indexInBlock,
                }) ||
                getExplorerEventUrl({
                  blockId: polkadotKeygenRequest?.event.block.id,
                  indexInBlock: polkadotKeygenRequest?.event.indexInBlock,
                })
              }
              timestamp={polkadotKeygenVerificationSuccess?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                polkadotKeygenRequest?.event.id,
                polkadotKeygenVerificationSuccess?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="EVM Keygen ceremony"
              linkToExplorer={
                getExplorerEventUrl({
                  blockId: evmKeygenVerificationSuccess?.event.block.id,
                  indexInBlock: evmKeygenVerificationSuccess?.event.indexInBlock,
                }) ||
                getExplorerEventUrl({
                  blockId: evmKeygenRequest?.event.block.id,
                  indexInBlock: evmKeygenRequest?.event.indexInBlock,
                })
              }
              timestamp={evmKeygenVerificationSuccess?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                evmKeygenRequest?.event.id,
                evmKeygenVerificationSuccess?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Bitcoin Keygen ceremony"
              linkToExplorer={
                getExplorerEventUrl({
                  blockId: bitcoinKeygenVerificationSuccess?.event.block.id,
                  indexInBlock: bitcoinKeygenVerificationSuccess?.event.indexInBlock,
                }) ||
                getExplorerEventUrl({
                  blockId: bitcoinKeygenRequest?.event.block.id,
                  indexInBlock: bitcoinKeygenRequest?.event.indexInBlock,
                })
              }
              timestamp={bitcoinKeygenVerificationSuccess?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                bitcoinKeygenRequest?.event.id,
                bitcoinKeygenVerificationSuccess?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Solana Keygen ceremony"
              linkToExplorer={
                getExplorerEventUrl({
                  blockId: solanaKeygenVerificationSucess?.event.block.id,
                  indexInBlock: solanaKeygenVerificationSucess?.event.indexInBlock,
                }) ||
                getExplorerEventUrl({
                  blockId: solanaKeygenRequest?.event.block.id,
                  indexInBlock: solanaKeygenRequest?.event.indexInBlock,
                })
              }
              timestamp={solanaKeygenVerificationSucess?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                solanaKeygenRequest?.event.id,
                solanaKeygenVerificationSucess?.event.id,
              )}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="text-12 text-cf-light-2">Key Handover</div>
          <div className="flex flex-col space-y-2">
            <RotationPhaseDetailRow
              title="Bitcoin Key handover"
              linkToExplorer={
                getExplorerEventUrl({
                  blockId: bitcoinKeyHandoverVerificationSuccess?.event.block.id,
                  indexInBlock: bitcoinKeyHandoverVerificationSuccess?.event.indexInBlock,
                }) ||
                getExplorerEventUrl({
                  blockId: bitcoinKeyHandoverRequest?.event.block.id,
                  indexInBlock: bitcoinKeyHandoverRequest?.event.indexInBlock,
                })
              }
              timestamp={bitcoinKeyHandoverVerificationSuccess?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                bitcoinKeyHandoverRequest?.event.id,
                bitcoinKeyHandoverVerificationSuccess?.event.id,
              )}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="text-12 text-cf-light-2">Vault Activation</div>
          <div className="flex flex-col space-y-2">
            <RotationPhaseDetailRow
              title="Polkadot Vault activation"
              linkToExplorer={getExplorerEventUrl({
                blockId: polkadotVaultActivationCompleted?.event.block.id,
                indexInBlock: polkadotVaultActivationCompleted?.event.indexInBlock,
              })}
              timestamp={polkadotVaultActivationCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                activatingKeysRotationPhase?.event.id,
                polkadotVaultActivationCompleted?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Ethereum Vault activation"
              linkToExplorer={getExplorerEventUrl({
                blockId: ethereumVaultActivationCompleted?.event.block.id,
                indexInBlock: ethereumVaultActivationCompleted?.event.indexInBlock,
              })}
              timestamp={ethereumVaultActivationCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                activatingKeysRotationPhase?.event.id,
                ethereumVaultActivationCompleted?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Arbitrum Vault activation"
              linkToExplorer={getExplorerEventUrl({
                blockId: arbitrumVaultActivationCompleted?.event.block.id,
                indexInBlock: arbitrumVaultActivationCompleted?.event.indexInBlock,
              })}
              timestamp={arbitrumVaultActivationCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                activatingKeysRotationPhase?.event.id,
                arbitrumVaultActivationCompleted?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Bitcoin Vault activation"
              linkToExplorer={getExplorerEventUrl({
                blockId: bitcoinVaultActivationCompleted?.event.block.id,
                indexInBlock: bitcoinVaultActivationCompleted?.event.indexInBlock,
              })}
              timestamp={bitcoinVaultActivationCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                activatingKeysRotationPhase?.event.id,
                bitcoinVaultActivationCompleted?.event.id,
              )}
            />
            <RotationPhaseDetailRow
              title="Solana Vault activation"
              linkToExplorer={getExplorerEventUrl({
                blockId: solanaVaultActivationCompleted?.event.block.id,
                indexInBlock: solanaVaultActivationCompleted?.event.indexInBlock,
              })}
              timestamp={solanaVaultActivationCompleted?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                activatingKeysRotationPhase?.event.id,
                solanaVaultActivationCompleted?.event.id,
              )}
            />
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          <div className="text-12 text-cf-light-2">Authority Set</div>
          <div className="flex flex-col space-y-2">
            <RotationPhaseDetailRow
              title="Authority Set rotation"
              linkToExplorer={getExplorerEventUrl({
                blockId: newKeysActivatedRotationPhase?.event.block.id,
                indexInBlock: newKeysActivatedRotationPhase?.event.indexInBlock,
              })}
              timestamp={newKeysActivatedRotationPhase?.event.block.timestamp}
              status={determineRotationPhaseStatus(
                undefined,
                newKeysActivatedRotationPhase?.event.id,
              )}
            />
          </div>
        </div>
      </div>
    </PlainModal>
  );
};
